import $ from 'jquery';
import prestashop from 'prestashop';

function setUpCheckout() {
  if ($('.js-cancel-address').length !== 0) {
    $('.checkout-step:not(.js-current-step) .step-title').addClass('not-allowed');
  }

  $('.js-terms a').on('click', function(event) {
    event.preventDefault();
    var url = $(event.target).attr('href');
    if (url) {
      // TODO: Handle request if no pretty URL
      url += '?content_only=1';
      $.get(url, function(content) {
        $('.js-modal-content').html($(content).find('.page-cms').contents());
        $('#modal').modal('show');
      }).fail(function(resp) {
        prestashop.emit('handleError', {eventType: 'clickTerms', resp: resp});
      });
    }
  });

  $('.js-gift-checkbox').on('click', function() {
    $('#gift').collapse('toggle');
  });
}

function initPersonalForm() {
  let guest_form = $('#checkout-guest-form'),
      login_form = $('#checkout-login-form');

  if (guest_form.length && (guest_form.find('input[name="email"]').val() != '' || guest_form.has('.help-block').length)) {
    login_form.hide();
    guest_form.show();
  }

  $('body').on('click', '.js-switch-personal-form', function (event) {
    let form_object = $($(this).attr('href'));
    $(this).closest('.personal-form').fadeOut(400, function() {
      form_object.fadeIn();
    });

    return false;
  });
  
}

$(document).ready(function() {
  if ($('body#checkout').length === 1) {
    setUpCheckout();
    initPersonalForm();
  }

  prestashop.on('updatedDeliveryForm', function(params) {
    if (typeof params.deliveryOption === 'undefined' || 0 === params.deliveryOption.length) {
      return;
    }
    // Hide all carrier extra content ...
    $('.carrier-extra-content').hide();
    // and show the one related to the selected carrier
    console.log(params.deliveryOption);
    params.deliveryOption.next('.carrier-extra-content').slideDown();
  });

  accordion.init({ speed: 300, oneOpen: true });

});

var accordion = (function(){
  
  var $accordion = $('.js-accordion');
  var $accordion_header = $accordion.find('.js-accordion-header');
 
  // default settings 
  var settings = {
    // animation speed
    speed: 400,
    
    // close all other accordion items if true
    oneOpen: false
  };
    
  return {
    // pass configurable object literal
    init: function($settings) {
      $accordion_header.on('click', function() {
        if($(this).parent().find('.accordion-body').is(":hidden")){
          accordion.toggle($(this));
        }      
      });
      
      $.extend(settings, $settings); 
      
      // ensure only one accordion is active if oneOpen is true
      if(settings.oneOpen && $('.js-accordion-item.active').length > 1) {
        $('.js-accordion-item.active:not(:first)').removeClass('active');
      }
      
      // reveal the active accordion bodies
      $('.js-accordion-item.active').find('> .js-accordion-body').show();
    },
    toggle: function($this) {
            
      if(settings.oneOpen && $this[0] != $this.closest('.js-accordion').find('> .js-accordion-item.active > .js-accordion-header')[0]) {
        $this.closest('.js-accordion')
               .find('> .js-accordion-item') 
               .removeClass('active')
               .find('.js-accordion-body')
               .slideUp()
      }
      
      // show/hide the clicked accordion item
      $this.closest('.js-accordion-item').toggleClass('active');
      $this.next().stop().slideToggle(settings.speed);
    }
  }
})();
function updateSlickInTabs() {
  $('.aone-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var slick = $(e.target).attr('data-slickID');
    $('#'+slick).slick('setPosition');
    $('.slick-active img.js-lazy', '#'+slick).trigger('appear');
  });
}

function setHomeBlockSlider(obj, opt) {
  $(obj).slick({
    slidesToShow: opt.slidesToShow,
    slidesToScroll: opt.slidesToShow,
    adaptiveHeight: false,
    infinite: true,
    speed: opt.speed,
    autoplay: opt.autoplay,
    dots: opt.dots,
    arrows: opt.arrows,
    rtl: opt.rtl,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: opt.slidesToShow_1220,
          slidesToScroll: opt.slidesToShow_1220,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: opt.slidesToShow_992,
          slidesToScroll: opt.slidesToShow_992,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: opt.slidesToShow_768,
          slidesToScroll: opt.slidesToShow_768,
        }
      }
    ],
  });
  
  $(obj).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $(obj).find('.slick-active img.js-lazy').trigger('appear');
  });
}

function productMobileSliderScroll() {
  $('.js-home-block-mobile-slider').each(function() {
    $(this).on('scroll', function () {
      if($(this).scrollLeft()) {
        $('img.js-lazy', $(this)).trigger('appear');
      }
    })
  });
}

$(window).load(function() {
  $('.js-home-block-slider').each(function() {
    setHomeBlockSlider($(this), $(this).data('slickoptions'));
  });

  updateSlickInTabs();
  productMobileSliderScroll();
});